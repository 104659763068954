$(document).on('turbolinks:load', function(){
    visor2()
});


const visor2 = () => {
    $("#btn_new").on("click",function(){
        $(".i",this).toggleClass('fa-eye-slash').toggleClass('fa-eye');
            const inp = $("#new_password")[0];
            inp.type=inp.type=="text"?"password":"text";
            console.log(inp);
    })

    $("#btn_confirm").on("click",function(){
        $(".i",this).toggleClass('fa-eye-slash').toggleClass('fa-eye');
            const inp = $("#confirm_password")[0];
            inp.type=inp.type=="text"?"password":"text";
            console.log(inp);
    })

    $("#btn_act").on("click",function(){
        $(".i",this).toggleClass('fa-eye-slash').toggleClass('fa-eye');
            const inp = $("#act_password")[0];
            inp.type=inp.type=="text"?"password":"text";
            console.log(inp);
    })
}