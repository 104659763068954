
$(document).on('turbolinks:load', function(){
    let submitButton = document.querySelector('input[type=submit]')
    if(submitButton && submitButton.value === "Descargar reporte"){
        submitButton.addEventListener('click', function(event) {
            setTimeout(function(){
                submitButton.disabled = false;
            }, 100);
        });
    }
});