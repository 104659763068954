
$(document).on('turbolinks:load', function(){
    div_assembly();
    $("#type_assembly").change(div_assembly);

    const {browser} = require('./application.js');
    let delay;
    if (browser === "firefox" || browser === "safari"){
        delay = 100;
    } else {
        delay = 600;
    }

    setTimeout( function() {
        let autofocusedElements = document.querySelector('#assembly_premise_description');
        if (autofocusedElements) {
            if (browser === "firefox" || browser === "safari"){
                autofocusedElements.scrollIntoView({behavior: "smooth", block: "center"});
            } else {
                autofocusedElements.focus();
            }
        }
    }, delay);
});

function div_assembly() {
    if($("#type_assembly option:selected").text() == "Asamblea General Extraordinaria de Accionistas de Inversiones Americana del Caribe (IAC) B.V."
        || $("#type_assembly option:selected").text() == "Asamblea General Ordinaria de Accionistas de Inversiones Americana del Caribe (IAC) B.V."){
        $('.iac').show();
        $('.rest').show();
        $('.increase').hide();
    }
    else if($("#type_assembly option:selected").text() == "Asamblea General Ordinaria de Accionistas"
        || $("#type_assembly option:selected").text() == "Asamblea General Extraordinaria de Accionistas"){
        $('.iac').hide();
        $('.rest').show();
        $('.increase').show();
    }
    else{
        $('.iac').hide();
        $('.rest').hide();
    }
}