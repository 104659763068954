
$(document).on('turbolinks:load', function(){
    div_watch();
    $("#watch").change(div_watch);
});

function div_watch() {
    if($("#watch option:selected").text() == "Acciones"){
        $('.stockholder_search').hide();
        $('.stock_search').show();
        $('.assembly_date').hide()
    }
    else if ($("#watch option:selected").text() == "Acciones Pendientes"){
        $('.stock_search').hide();
        $('.stockholder_search').hide();
        $('.assembly_date').show()
    }
    else if ($("#watch option:selected").text() == "Accionistas"){
        $('.stockholder_search').show();
        $('.stock_search').hide();
        $('.assembly_date').hide()
    }
}