
$(document).on('turbolinks:load', function(){
    div_type_user();
    $("#type_user").change(div_type_user);

    $("#user_type_document").change(completeDocument);
    $("#user_document").change(completeDocument);
    $("#user_type_document_2").change(completeDocument);
    $("#user_document_2").change(completeDocument);
});

function div_type_user() {
    if($("#type_user option:selected").text() == "Natural"){
        $('.natural_person').show();
        $('.legal_person').hide();
        $('.rest').show();
    }
    else if($("#type_user option:selected").text() == "Juridico"){
        $('.legal_person').show();
        $('.natural_person').hide();
        $('.rest').show();
    }
    else{
        $('.natural_person').hide();
        $('.legal_person').hide();
        $('.rest').hide();
    }
}

function completeDocument() {
    let username = $("#user_username");
    if($("#type_user option:selected").text() == "Natural"){
        let type_document = $("#user_type_document option:selected").text();
        let document = $("#user_document").val();
        username.val(type_document + document);
    }
    else if($("#type_user option:selected").text() == "Juridico"){
        let type_document = $("#user_type_document_2 option:selected").text();
        let document = $("#user_document_2").val();
        username.val(type_document + document);
    }
}