// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.


// require('jquery')
// import Rails from "@rails/ujs"
// import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"
// import $ from 'jquery'

// Rails.start()
// Turbolinks.start()
// ActiveStorage.start()


// No hace falta añadir Popper o jQuery aquí porque Bootstrap los llamará internamente
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('bootstrap')
require('bootstrap-datepicker')
require("@fortawesome/fontawesome-free/js/all")
require("@nathanvda/cocoon")
// Custom JS
require('packs/registration')
require('packs/edit_stockholder')
require('packs/search_stockstockholder')
require('packs/new_assembly')
require('packs/reports')
require('packs/visor_contraseña')

$(document).on("turbolinks:load", function () {
    components_ready();
    let browser = fnBrowserDetect();  
    module.exports = {browser};
    showModal();
    showSpinner();
    enable_button();
    truncate_document_string();
    show_deceased_date();
});

$.fn.datepicker.dates['es'] = {
    days: ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"],
    daysShort: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sab"],
    daysMin: ["D", "L", "M", "X", "J", "V", "S"],
    months: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
    monthsShort: ["Ene", "Feb", "Mar", "Abr", "May", "Jun", "Jul", "Ago", "Sep", "Oct", "Nov", "Dic"],
    today: "Hoy",
    clear: "Limpiar",
    format: "dd/mm/yyyy",
    titleFormat: "MM yyyy", /* Leverages same syntax as 'format' */
    weekStart: 0
};

function fnBrowserDetect(){
                 
    let userAgent = navigator.userAgent;
    let browserName;
    
    if(userAgent.match(/chrome|chromium|crios/i)){
        browserName = "chrome";
      }else if(userAgent.match(/firefox|fxios/i)){
        browserName = "firefox";
      }  else if(userAgent.match(/safari/i)){
        browserName = "safari";
      }else if(userAgent.match(/opr\//i)){
        browserName = "opera";
      } else if(userAgent.match(/edg/i)){
        browserName = "edge";
      }else{
        browserName="No browser detection";
      }
      return browserName;
}

function components_ready(){
    let date = new Date()
    let month = date.getMonth() == 11 ? 0 : date.getMonth() + 1
    let year = month == 0 ? date.getFullYear() + 1 : date.getFullYear()
    $('.calendar-full-month').datepicker({
        format: 'dd-mm-yyyy',
        endDate: new Date(year, month, 0), // Asi seleccionamos hasta el último día del mes
        language: 'es',
    })

    $('.calendar').datepicker({
        format: 'dd-mm-yyyy',
        endDate: '-1d',
        language: 'es',
    });

    $('.assembly-calendar').datepicker({
        format: 'dd/mm/yyyy',
        startDate: '+1d',
        language: 'es',
        daysOfWeekDisabled: '0,6',
    });

    $('.report-calendar').datepicker({
      format: 'dd-mm-yyyy',
      endDate: '-1d',
      startDate: new Date('2022-04-13'), // Día inicial 12-04-2022
      daysOfWeekDisabled: '0,6',
      language: 'es',
  });

    $('.poll-calendar').datepicker({
      format: 'dd-mm-yyyy',
      startDate: new Date(), 
      daysOfWeekDisabled: '0,6',
      endDate: "+1y",
      language: 'es'
    })
}

const showModal = () => {
  if ($("#exampleModal").length > 0){
      $("#exampleModal").modal('show')
  }
}

const showSpinner = () => {
  document.querySelector('form').addEventListener('submit', function(event) {
  document.getElementById('spinner').style.display = 'block';
  });
}

const truncate_document_string = () => {
  document.getElementById("assembly_document").addEventListener("change", function() {
    var fileName = this.files[0].name;
    if (fileName.length > 30) {
      fileName = fileName.substring(0, 30) + '...';
    }
    document.querySelector(".file-name").textContent = fileName;
  }); 
}

const enable_button = () => {
  document.querySelector("#assembly_document").addEventListener("change", function() {
    document.querySelector("#submit-button").removeAttribute("disabled");
  });
}

// No scroll cuando recargo la página
$(window).on("scroll",function () {
    sessionStorage.scrollTop = $(this).scrollTop();
});
$(document).on("ready",function () {
    if (sessionStorage.scrollTop != "undefined") {
        $(window).scrollTop(sessionStorage.scrollTop);
    }
});

// Barra de Progreso para carga de archivos
addEventListener("direct-upload:initialize", event => {
  const { target, detail } = event
  const { id, file } = detail
  target.insertAdjacentHTML("beforebegin", `
    <div id="direct-upload-${id}" class="direct-upload direct-upload--pending">
      <div id="direct-upload-progress-${id}" class="direct-upload__progress" style="width: 0%"></div>
      <span class="direct-upload__filename text-dark fw-bold">${file.name}</span>
    </div>
  `)
  $("#paperclip").hide()
})
addEventListener("direct-upload:start", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.remove("direct-upload--pending")
})
addEventListener("direct-upload:progress", event => {
  const { id, progress } = event.detail
  const progressElement = document.getElementById(`direct-upload-progress-${id}`)
  progressElement.style.width = `${progress}%`
})
addEventListener("direct-upload:error", event => {
  event.preventDefault()
  const { id, error } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--error")
  element.setAttribute("title", error)
})
addEventListener("direct-upload:end", event => {
  const { id } = event.detail
  const element = document.getElementById(`direct-upload-${id}`)
  element.classList.add("direct-upload--complete")
})