
$(document).on('turbolinks:load', function(){
    div_foreign_number();
    div_type_user();
    focus_document();
    show_deceased_date();
    $("#type_document").change(div_type_user)
    $("#foreign").change(div_foreign_number);
    
    const {browser} = require('./application.js');
    let delay;
    if (browser === "firefox" || browser === "safari"){
        delay = 100;
    } else {
        delay = 600;
    }

    let id = document.getElementById('stockholder_id');
    let autofocusedElements = document.querySelector('#stockholder_phone_region');
    if (id && id.value !== "") {
        if (autofocusedElements) {
            setTimeout( function() {
                if (browser === "firefox" || browser === "safari"){
                    autofocusedElements.scrollIntoView({behavior: "smooth", block: "center"});
                } else {
                    autofocusedElements.focus();
                }
            }, delay);
        } else {
            autofocusedElements = document.querySelector('#stockholder_email_email');
            if (autofocusedElements) {
                setTimeout( function() {
                    if (browser === "firefox" || browser === "safari"){
                        autofocusedElements.scrollIntoView({behavior: "smooth", block: "center"});
                    } else {
                        autofocusedElements.focus();
                    }
                }, delay);
            }
        }
    }
});

function div_foreign_number() {
    if($("#foreign:checked").val()){
        $('.foreign_field').show();
    }
    else{
        $('.foreign_field').hide();
    }
}

function div_type_user() {
    if($("#type_document option:selected").text() == "V" || 
            $("#type_document option:selected").text() == "T" || 
            $("#type_document option:selected").text() == "P" || 
            $("#type_document option:selected").text() == "E"){
        $('.natural_person').show();
    }
    else if($("#type_document option:selected").text() == "J" || 
            $("#type_document option:selected").text() == "G" || 
            $("#type_document option:selected").text() == "A" ||
            $("#type_document option:selected").text() == "X"){
        $('.natural_person').hide();
    }
    else{
        $('.natural_person').hide();
    }
}

const focus_document = () => {
    const text = 'Documento eliminado con éxito';

    for (const div of document.querySelectorAll('div')) {
      if (div.textContent.includes(text)) {
         document.getElementById("document_table").scrollIntoView()
      }
    }    
}

const show_deceased_date = () => {
    // Mostrar el input "deceased_date" si "deceased" está marcado al cargar la página
    if ($("#deceased").is(":checked")) {
      $("#deceased_date").show();
    } else {
      $("#deceased_date").hide();
    }
  
    // Escuchar los cambios en el input "deceased"
    $("#deceased").change(function() {
      if ($(this).is(":checked")) {
        // Mostrar el input "deceased_date" si "deceased" está marcado
        $("#deceased_date").show();
      } else {
        // Ocultar el input "deceased_date" si "deceased" no está marcado
        $("#deceased_date").hide();
      }
    });
  }